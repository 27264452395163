const config = {
    API_URL: 'https://signaltuner-back.azurewebsites.net/api/User',
    // API_URL: 'https://localhost:7090/api/User',

    // serviceId: 1,
    // serviceLogoURL: "https://www.signaltuner.com/images/is-service-down/zoom.png"

    // serviceId: 3,
    // serviceLogoURL: "https://www.signaltuner.com/images/is-service-down/msteams.png"

    serviceId: 41,
    serviceLogoURL: "https://www.signaltuner.com/images/is-service-down/microsoft365.png"
  };
  
  export default config;