import React from "react";

export const IncidentExpandSVG = ({ isExpanded }) => {
    return (
      <span
        style={{
          marginLeft: "10px",
          display: "inline-block",
          transition: "transform 0.3s",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="44" // Adjust width and height as necessary
          height="44"
          viewBox="0 0 44 44"
          fill="none"
        >
          <path
            d="M2.51502 13.458C2.45488 13.3944 2.40786 13.3195 2.37665 13.2377C2.34544 13.1559 2.33065 13.0687 2.33312 12.9812C2.3356 12.8936 2.35529 12.8074 2.39108 12.7275C2.42687 12.6476 2.47804 12.5755 2.54169 12.5153C2.60534 12.4552 2.68021 12.4082 2.76203 12.377C2.84385 12.3458 2.93101 12.331 3.01855 12.3334C3.10608 12.3359 3.19227 12.3556 3.27219 12.3914C3.35212 12.4272 3.42421 12.4784 3.48436 12.542L9.151 18.542C9.268 18.6658 9.33333 18.8297 9.33333 19C9.33333 19.1703 9.268 19.3342 9.151 19.458L3.48436 25.4587C3.42461 25.5237 3.35253 25.5762 3.27231 25.6132C3.1921 25.6501 3.10534 25.6708 3.01707 25.6739C2.92881 25.6771 2.8408 25.6626 2.75817 25.6315C2.67553 25.6003 2.59991 25.553 2.53569 25.4924C2.47148 25.4317 2.41995 25.359 2.38411 25.2782C2.34826 25.1975 2.32882 25.1105 2.32689 25.0222C2.32497 24.9339 2.34061 24.8461 2.37291 24.7639C2.4052 24.6817 2.45351 24.6067 2.51502 24.5433L7.74969 19L2.51502 13.458Z"
            fill="#586675"
            style={{
                transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
                transformOrigin: "center",
                transition: "transform 0.3s ease",
              }}
          />
          <defs>
            <filter
              id="filter0_d_10_379"
              x="0"
              y="0"
              width="44"
              height="44"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.4 0 0 0 0 0.552941 0 0 0 0 0.690196 0 0 0 0.15 0"
              />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10_379" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10_379" result="shape" />
            </filter>
          </defs>
        </svg>
      </span>
    );
  };