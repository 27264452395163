import React, { useState, useEffect } from "react";
import "./App.css";
import config from "./config";
import Navbar from "./Navbar";
import { IncidentExpandSVG } from "./SVGs.js";

function App() {
  const API_URL = config.API_URL;
  const serviceId = config.serviceId;
  const serviceLogoURL = config.serviceLogoURL;
  const [serviceStatus, setServiceStatus] = useState(null);
  const [serviceIncidents, setServiceIncidents] = useState([]);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [signupMessage, setSignupMessage] = useState(null);
  

  useEffect(() => {
    // Fetch service status
    fetch(`${API_URL}/GetServiceStatus/${serviceId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        setServiceStatus(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });

    // Fetch service incidents
    fetch(`${API_URL}/GetServiceIncidents/${serviceId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        setServiceIncidents(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [API_URL]);

  const getStatusStyle = (status) => {
    switch (status) {
      case "Operational":
        return { color: "#3BB537" };
      case "Active incident":
        return { color: "#F9AE00" };
      case "Outage":
        return { color: "#EC2F3E" };
      default:
        return {};
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSignup = () => {
    // Validate email
    if (!email) {
      alert("Please enter a valid email address.");
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Call the AlertEmailSignup API endpoint
    fetch(`${API_URL}/AlertEmailSignup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, serviceId }),
    })
      .then((response) => {
        if (response.status === 409) {
          // Email already subscribed
          alert("This email is already subscribed.");
          throw new Error("Email already subscribed");
        } else if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        setSignupMessage(data.message);
        setEmail(""); // Clear the email field after successful signup
      })
      .catch((error) => {
        if (error.message !== "Email already subscribed") {
          setSignupMessage("An error occurred while signing up. Please try again.");
        }
      });
  };

  const sortedServiceIncidents = serviceIncidents.sort((a, b) => new Date(b.PublishDate) - new Date(a.PublishDate));

  return (
    <div className="App">
      <div><Navbar/></div>
      
      <header className="App-header">
        {serviceStatus && (
          <>
            <h1 className="App-headline-title">Is {serviceStatus.DisplayName} Down?</h1>
            <img className="App-service-logo" src={serviceLogoURL} alt="Service Logo"></img>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            <h2 className="App-current-status">
              The current status of <span className="App-current-status-display-name">{serviceStatus.DisplayName}</span> is:{" "}
              <span className="App-current-status-display-status" style={getStatusStyle(serviceStatus.CurrentStatus)}>
                "{serviceStatus.CurrentStatus}"
              </span>
            </h2>
            <h3 className="App-signup-label">Sign up for alerts when {serviceStatus.DisplayName} has an incident or outage.</h3>
            <div className="App-email-container">
              <input className="App-email-input" type="email" value={email} onChange={handleEmailChange} placeholder="Enter your email" />
              <button className="App-email-submit-button" onClick={handleSignup}>
                Submit
              </button>
            </div>
            {signupMessage && <p className="App-signup-message">{signupMessage}</p>}
            <div className="App-alerts-container" onClick={() => window.open("https://signaltuner.com", "_blank", "noopener,noreferrer")}>
              <div className="App-signup-logo-container">
                <p className="App-signup-message">Alerts provided by</p>
                <img className="App-alerts-logo" src="https://www.signaltuner.com/images/SignalTUNER%20Logo%20Horizontal-01.png" alt="SignalTuner Logo" />
              </div>
              <div>
                <h4 className="App-question-content">Are you experiencing issues with {serviceStatus.DisplayName}?</h4>
              </div>
              <div>
                <h4 className="App-question-content">
                  SignalTuner combines service statuses with device monitoring to diagnose connectivity issues everywhere.
                </h4>
              </div>
              <div>
                <h3 className="App-question-content">Try it free!</h3>
              </div>
            </div>
          </>
        )}
        {sortedServiceIncidents.length > 0 && (
          <div className="App-recent-incidents-div">
            <h2 className="App-recent-incidents-label">Recent Incidents:</h2>
            <div>
              {sortedServiceIncidents
                .sort((a, b) => {
                  // Move "Active" incidents to the top
                  if (a.Status === "Active" && b.Status !== "Active") return -1;
                  if (a.Status !== "Active" && b.Status === "Active") return 1;
                  return 0; // Keep the order for incidents with the same status
                })
                .slice(0, 50)
                .map((incident) => (
                  <ExpandableIncident key={incident.IncidentId} incident={incident} serviceStatus={serviceStatus} />
                ))}
            </div>
          </div>
        )}
        {sortedServiceIncidents.length === 0 && !loading && <p>No recent incidents reported.</p>}
      </header>
    </div>
  );
}

const ExpandableIncident = ({ incident, serviceStatus }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="App-incident-div" onClick={toggleExpand}>
      {/* Title */}
      <h3 className="App-incident-title" style={{ color: incident.Status === "Active" ? "#F9AE00" : "#3BB537" }}>
        {incident.Title}
      </h3>
      
      {/* Status and SVG Container */}
      <div
        
        style={{ cursor: "pointer", display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <h4>
          Status:{" "}
          <span style={{ color: incident.Status === "Active" ? "#F9AE00" : "#3BB537" }}>
            {incident.Status}
          </span>
        </h4>
  
        {/* Arrow SVG */}
        <IncidentExpandSVG isExpanded={isExpanded} />
      </div>
  
      {/* Expanded Content */}
      {isExpanded && (
        <div>
          <div className="App-incident-description" dangerouslySetInnerHTML={{ __html: incident.Description }} />
          <h4 className="App-incident-link">
            <a href={incident.Link} target="_blank" rel="noopener noreferrer">
              {serviceStatus ? serviceStatus.DisplayName : "Service"} incident report
            </a>
          </h4>
          <p>Published on: {new Date(incident.PublishDate).toLocaleDateString()}</p>
        </div>
      )}
    </div>
  );
  
};

export default App;
